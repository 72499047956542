import React from 'react';
import { Switch, Route, Link } from "react-router-dom";
import './App.css';
import { initializeIcons } from '@uifabric/icons';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IButtonProps } from '@fluentui/react/lib/Button';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';

import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";

initializeIcons();

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

export interface ICommandBarState {
  currentUser: any;
  showModeratorBoard: boolean;
  showAdminBoard: boolean;
  navItems: ICommandBarItemProps[];
  overflowItems: ICommandBarItemProps[];
  farItems: ICommandBarItemProps[];
}

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
};

const options: IDropdownOption[] = [
  { key: 'fruitsHeader', text: 'Meine Bestellungen' },
  { key: 'apple', text: 'Vorhandenes Inventar' },
  { key: 'banana', text: 'Verkauftes Inventar' },
  { key: 'orange', text: 'Verschenktes Inventar' },
  { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
  { key: 'vegetablesHeader', text: 'Rücksendungen' },
];

export default class App extends React.Component<{}, ICommandBarState>{
  constructor(props: {}) {
    super(props);
    this.logOut = this.logOut.bind(this);

    const _items: ICommandBarItemProps[] = [
      {
        key: 'myLists',
        text: 'Meine Listen',
        cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
        iconProps: { iconName: 'ViewList' },
        subMenuProps: {
          items: [
            {
              key: 'myInventar',
              text: 'Mein Inventar',
              iconProps: { iconName: 'Devices3' },
              ['data-automation-id']: 'newEmailButton', // optional
            },
            {
              key: 'categories',
              text: 'Kategorien',
              iconProps: { iconName: 'StackIndicator' },
            },
            {
              key: 'kreditor',
              text: 'Kreditoren',
              iconProps: { iconName: 'Shop' },
            },
            {
              key: 'kostenstelle',
              text: 'Kostenstellen',
              iconProps: { iconName: 'EntitlementRedemption' },
            },
          ],
        },
      },
      {
        key: 'userBoard',
        text: 'User-Board',
        iconProps: { iconName: 'FabricUserFolder' },
        href: '/user',
      },
      {
        key: 'share',
        text: 'Share',
        iconProps: { iconName: 'Share' },
        onClick: () => console.log('Share'),
      },
      {
        key: 'download',
        text: 'Download',
        iconProps: { iconName: 'Download' },
        onClick: () => console.log('Download'),
      },
    ];

    const _overflowItems: ICommandBarItemProps[] = [
      { key: 'move', text: 'Move to...', onClick: () => console.log('Move to'), iconProps: { iconName: 'MoveToFolder' } },
      { key: 'copy', text: 'Copy to...', onClick: () => console.log('Copy to'), iconProps: { iconName: 'Copy' } },
      { key: 'rename', text: 'Rename...', onClick: () => console.log('Rename'), iconProps: { iconName: 'Edit' } },
    ];

    const _farItems: ICommandBarItemProps[] = [
      {
        key: 'tile',
        text: 'Grid view',
        // This needs an ariaLabel since it's icon-only
        ariaLabel: 'Grid view',
        iconOnly: true,
        iconProps: { iconName: 'Tiles' },
        onClick: () => console.log('Tiles'),
      },
      {
        key: 'info',
        text: 'Info',
        // This needs an ariaLabel since it's icon-only
        ariaLabel: 'Info',
        iconOnly: true,
        iconProps: { iconName: 'Info' },
        onClick: () => console.log('Info'),
      },
    ];

    this.state = {
      currentUser: undefined,
      showAdminBoard: false,
      showModeratorBoard: false,
      navItems: _items,
      overflowItems: _overflowItems,
      farItems: _farItems
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
  }

  logOut() {
    AuthService.logout();
  }

  public render() {
    const { currentUser, showModeratorBoard, showAdminBoard, navItems, overflowItems, farItems } = this.state;

    return (
      <div className="App">
        <CommandBar
          items={navItems}
          overflowItems={overflowItems}
          overflowButtonProps={overflowProps}
          farItems={farItems}
          ariaLabel="Benutze Links- und Rechtscursor um durch die Navigation zu wechseln"
        />
        <header className="App-header">
          <Link to={"/login"} className="nav-link">
            Login
          </Link>
          <Dropdown
            placeholder="Wähle eine Opition aus"
            label="Ansicht"
            options={options}
            styles={dropdownStyles}
          />
          <div>
            <Switch>
              <Route exact path={["/", "/home"]} component={Home} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/profile" component={Profile} />
              <Route path="/user" component={BoardUser} />
              <Route path="/mod" component={BoardModerator} />
              <Route path="/admin" component={BoardAdmin} />
            </Switch>
          </div>
        </header>
      </div>
    );
  }
}